import { fetchAlert, fetchStart, fetchSuccess } from '../../../redux/actions';
import {
  setAuthorizations,
  setNavigationMenu,
  setAuthUser,
  setLogout,
  setForgetPassMailSent,
  updateLoadUser,
  setWidgets,
  setProfileWidgets,
  setTeamStructure,
} from '../../../redux/actions/Auth';
import React from 'react';
import MainServices from '../../MainServices';
import UserService from 'services/Admin/UserService';
import Alert from 'models/Generals/Alert';
import GeneralsHelper from 'helpers/GeneralsHelper';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import { AuhMethods } from '../../../services/auth';
import { setNotifications } from 'redux/actions/Notification';
import TeamService from 'services/Core/TeamService';

const BasicAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        const user = {
          name: name,
          email: email,
          password: password,
        };
        dispatch(setAuthUser(user));
      }, 300);
    };
  },

  onLogin: ({ user, userAdmin, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      UserService.postUserLogin({
        username: user,
        username_admin: userAdmin,
        password: password,
      }).then(response => {
        dispatch(fetchAlert(Alert.Set(response.satisfactory, response.title, response.message)));
        dispatch(fetchSuccess());
        if (response.satisfactory) {
          dispatch(setAuthUser(response.data.user));
          dispatch(setAuthorizations(response.data.authorizations));
          dispatch(setNavigationMenu(response.data.navigationMenu));
          dispatch(setWidgets(response.data.widgets));
          dispatch(setProfileWidgets(response.data.profileWidgets));
          TeamService.getTeamHasManagementUser().then(response => {
            if (response.satisfactory) {
              dispatch(setTeamStructure(response.data));
            }
          });
        }
      });
    };
  },

  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      UserService.postUserLogout().then(response => {
        dispatch(fetchSuccess());
        dispatch(setLogout());
        if (response.satisfactory) {
          dispatch(fetchAlert(Alert.Set(response.satisfactory, response.title, response.message)));
          return;
        }
      });
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(() => {
        dispatch(fetchSuccess());
        let userLocal = localStorage.getItem('user');
        if (userLocal !== null && userLocal !== undefined && userLocal?.length > 0) {
          if (GeneralsHelper.IsJsonString(userLocal)) {
            dispatch(AuhMethods[CurrentAuthMethod].onLogout());
            return;
          }
        }
        let dataUser = MainServices.DecryptData(localStorage.getItem('user'));
        dispatch(setAuthUser(dataUser));
        let dataMenu = MainServices.DecryptData(localStorage.getItem('navigationMenu'));
        dispatch(setNavigationMenu(dataMenu));
        let dataAuthorizations = MainServices.DecryptData(localStorage.getItem('authorizations'));
        dispatch(setAuthorizations(dataAuthorizations));
        let dataNotifications = MainServices.DecryptData(localStorage.getItem('notifications'));
        dispatch(setNotifications(dataNotifications));

        let dataWidgets = MainServices.DecryptData(localStorage.getItem('widgets'));
        dataWidgets && dispatch(setWidgets(dataWidgets));

        let dataProfileWidgets = MainServices.DecryptData(localStorage.getItem('profileWidgets'));
        dataProfileWidgets && dispatch(setProfileWidgets(dataProfileWidgets));

        let teamStructure = MainServices.DecryptData(localStorage.getItem('teamStructure'));
        teamStructure && dispatch(setTeamStructure(teamStructure));
      }, 300);
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default BasicAuth;
