import { Typography } from '@material-ui/core';
import React from 'react';
import * as IconsFa from 'react-icons/fa';
import * as IconsAi from 'react-icons/ai';
import * as IconsBs from 'react-icons/bs';
import * as IconsBi from 'react-icons/bi';
import * as IconsDi from 'react-icons/di';
import * as IconsFi from 'react-icons/fi';
import * as IconsFc from 'react-icons/fc';
import * as IconsGi from 'react-icons/gi';
import * as IconsGo from 'react-icons/go';
import * as IconsGr from 'react-icons/gr';
import * as IconsHi from 'react-icons/hi';
import * as IconsIm from 'react-icons/im';
import * as IconsIo from 'react-icons/io';
import * as IconsIo5 from 'react-icons/io5';
import * as IconsMd from 'react-icons/md';
import * as IconsRi from 'react-icons/ri';
import * as IconsSi from 'react-icons/si';
import * as IconsTi from 'react-icons/ti';
import * as IconsVsc from 'react-icons/vsc';
import * as IconsWi from 'react-icons/wi';
import * as IconsCg from 'react-icons/cg';
import PropTypes from 'prop-types';

const DynamicIconReact = ({ name, size, ...rest }) => {
  if (name === null || name?.length === 0) {
    return <Typography variant="caption">EMPTY</Typography>;
  }
  let IconComponent = IconsFa[name];
  switch (name.substring(0, 2).toLowerCase()) {
    case 'ai':
      IconComponent = IconsAi[name];
      break;
    case 'bs':
      IconComponent = IconsBs[name];
      break;
    case 'bi':
      IconComponent = IconsBi[name];
      break;
    case 'di':
      IconComponent = IconsDi[name];
      break;
    case 'fi':
      IconComponent = IconsFi[name];
      break;
    case 'fc':
      IconComponent = IconsFc[name];
      break;
    case 'gi':
      IconComponent = IconsGi[name];
      break;
    case 'go':
      IconComponent = IconsGo[name];
      break;
    case 'gr':
      IconComponent = IconsGr[name];
      break;
    case 'hi':
      IconComponent = IconsHi[name];
      break;
    case 'im':
      IconComponent = IconsIm[name];
      break;
    case 'io':
      IconComponent = IconsIo[name];
      if (!IconComponent) {
        IconComponent = IconsIo5[name];
      }
      break;
    case 'md':
      IconComponent = IconsMd[name];
      break;
    case 'ri':
      IconComponent = IconsRi[name];
      break;
    case 'si':
      IconComponent = IconsSi[name];
      break;
    case 'ti':
      IconComponent = IconsTi[name];
      break;
    case 'vs':
      IconComponent = IconsVsc[name];
      break;
    case 'wi':
      IconComponent = IconsWi[name];
      break;
    case 'cg':
      IconComponent = IconsCg[name];
      break;
    default:
      break;
  }

  switch (size) {
    case 'xs':
      size = 16;
      break;
    case 'sm':
      size = 20;
      break;
    case 'md':
      size = 24;
      break;
    case 'lg':
      size = 28;
      break;
    case 'xl':
      size = 32;
      break;

    default:
      break;
  }
  if (!IconComponent) {
    return <Typography variant="caption">EMPTY</Typography>;
  }

  return <IconComponent size={size ?? 16} {...rest} />;
};

DynamicIconReact.propTypes = {
  name: PropTypes.string,
  size: PropTypes.any,
};

DynamicIconReact.defaultProps = {
  name: null,
  size: 'xs',
};

export default DynamicIconReact;
