class Access {
  static Set(accessReference, moduleId = null, accessId = null) {
    return {
      access_reference: accessReference,
      module_id: moduleId,
      access_id: accessId,
    };
  }
}

export default Access;
