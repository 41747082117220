import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, fade } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { NavLink } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import { fetchAlert } from 'redux/actions';
import Alert from 'models/Generals/Alert';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));

const SignInAdmin = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [user, setUser] = useState('');
  const [userAdmin, setUserAdmin] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const onSubmit = () => {
    if (!validateForm()) {
      dispatch(fetchAlert(Alert.Set(false, 'DATOS FALTANTES', 'Es necesario completar el formulario')));
      return;
    }
    dispatch(AuhMethods[method].onLogin({ user, userAdmin, password }));
  };

  const validateForm = () => {
    if (!user && user === '') {
      setErrors({ user: 'Es necesario llenar el campo Usuario' });
      return false;
    }
    if (!userAdmin && userAdmin === '') {
      setErrors({ userAdmin: 'Es necesario llenar el campo Usuario Admin' });
      return false;
    }
    if (!password && password === '') {
      setErrors({ password: 'Es necesario llenar el campo Contraseña' });
      return false;
    }
    setErrors(null);
    return true;
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo.png'} style={{ width: '150px' }} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}></Typography>
        <form>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.user" />}
              fullWidth
              onChange={event => setUser(event.target.value)}
              defaultValue={user}
              margin="normal"
              onKeyPress={event => event.charCode === 13 && onSubmit()}
              variant="outlined"
              className={classes.textFieldRoot}
              error={errors?.user}
              helperText={errors?.user}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.userAdmin" />}
              fullWidth
              onChange={event => setUserAdmin(event.target.value)}
              defaultValue={user}
              margin="normal"
              variant="outlined"
              onKeyPress={event => event.charCode === 13 && onSubmit()}
              className={classes.textFieldRoot}
              error={errors?.userAdmin}
              helperText={errors?.userAdmin}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              onKeyPress={event => event.charCode === 13 && onSubmit()}
              className={classes.textFieldRoot}
              error={errors?.password}
              helperText={errors?.password}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <FormControlLabel
              className={classes.formcontrolLabelRoot}
              control={<Checkbox name="checkedA" />}
              label="Recordar"
            />
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/forgot-password">
                <IntlMessages id="appModule.forgotPassword" />
              </NavLink>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button
              onKeyPress={event => event.charCode === 13 && onSubmit()}
              onClick={onSubmit}
              variant="contained"
              color="primary">
              <IntlMessages id="appModule.signIn" />
            </Button>
          </Box>
        </form>

        {dispatch(AuhMethods[method].getSocialMediaIcons())}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignInAdmin;
