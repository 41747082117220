import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';

const App = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/home`} />
        <Route path={`${requestedUrl}/home`} component={lazy(() => import('./Home'))} />

        <Route
          exact
          path={`${requestedUrl}/administration/users/:id/:name`}
          component={lazy(() => import('./Administration/Users/Authorization'))}
        />
        <Route path={`${requestedUrl}/administration/users`} component={lazy(() => import('./Administration/Users'))} />
        <Route path={`${requestedUrl}/administration/colors`} component={lazy(() => import('./Administration/Colors'))} />
        <Route path={`${requestedUrl}/administration/icons`} component={lazy(() => import('./Administration/Icons'))} />

        <Route
          path={`${requestedUrl}/planning/planning-visits`}
          component={lazy(() => import('./Planning/PlanningVisits'))}
        />
        <Route path={`${requestedUrl}/planning/pre-allocation`} component={lazy(() => import('./Planning/PreAllocation'))} />
        <Route
          path={`${requestedUrl}/planning/assignment-confirmation`}
          component={lazy(() => import('./Planning/AssignmentConfirmation'))}
        />
        <Route path={`${requestedUrl}/planning/approval`} component={lazy(() => import('./Planning/Approval'))} />
        <Route path={`${requestedUrl}/planning/portfolio`} component={lazy(() => import('./Planning/Portfolio'))} />

        {/* ROUTES Activities */}
        <Route
          path={`${requestedUrl}/administration/activities`}
          component={lazy(() => import('./Administration/Activities'))}
        />
        <Route
          path={`${requestedUrl}/administration/interactions-types/status`}
          component={lazy(() => import('./Administration/InteractionTypesStatus'))}
        />
        <Route
          path={`${requestedUrl}/administration/interactions-types`}
          component={lazy(() => import('./Administration/InteractionTypes'))}
        />
        {/* END ROUTES Activities */}
        {/* ROUTES Surveys */}
        <Route
          path={`${requestedUrl}/administration/surveys/questions`}
          component={lazy(() => import('./Administration/Surveys/Questions'))}
        />
        <Route
          exact
          path={`${requestedUrl}/administration/platforms/versions_histories/:id/:name`}
          component={lazy(() => import('./Administration/Platforms/VersionHistories'))}
        />
        <Route
          exact
          path={`${requestedUrl}/administration/kpis/periods/:id/:name`}
          component={lazy(() => import('./Core/KpisPeriod'))}
        />
        <Route path={`${requestedUrl}/administration/kpis`} component={lazy(() => import('./Core/Kpis'))} />
        <Route
          path={`${requestedUrl}/administration/platforms`}
          component={lazy(() => import('./Administration/Platforms'))}
        />
        <Route path={`${requestedUrl}/administration/surveys`} component={lazy(() => import('./Administration/Surveys'))} />
        <Route
          path={`${requestedUrl}/administration/lots/entity`}
          component={lazy(() => import('./Administration/Transactional/Entities'))}
        />
        <Route
          path={`${requestedUrl}/administration/lots`}
          component={lazy(() => import('./Administration/Transactional/Lots'))}
        />
        {/* ROUTES Sales */}
        <Route
          exact
          path={`${requestedUrl}/administration/itemsCategories/fields/:id/:name`}
          component={lazy(() => import('./Sales/ItemsCategoryFiields'))}
        />
        <Route
          path={`${requestedUrl}/administration/itemsCategories`}
          component={lazy(() => import('./Sales/ItemsCategory'))}
        />
        <Route path={`${requestedUrl}/administration/items`} component={lazy(() => import('./Sales/Items'))} />

        {/* END ROUTES Surveys */}
        <Route
          path={`${requestedUrl}/administration/provinces/cantons/parishes`}
          component={lazy(() => import('./Administration/Localities/Parishes'))}
        />
        <Route
          path={`${requestedUrl}/administration/provinces/cantons`}
          component={lazy(() => import('./Administration/Localities/Cantons'))}
        />
        <Route
          path={`${requestedUrl}/administration/provinces`}
          component={lazy(() => import('./Administration/Localities/Provinces'))}
        />
        <Route
          path={`${requestedUrl}/administration/cities`}
          component={lazy(() => import('./Administration/Localities/Cities'))}
        />

        <Route
          path={`${requestedUrl}/administration/campaigns`}
          component={lazy(() => import('./Administration/Campaigns'))}
        />

        <Route
          path={`${requestedUrl}/administration/sectorizations/sectors`}
          component={lazy(() => import('./Administration/Localities/Sectors'))}
        />
        <Route
          path={`${requestedUrl}/administration/sectorizations`}
          component={lazy(() => import('./Administration/Localities/Sectorizations'))}
        />

        <Route path={`${requestedUrl}/profile`} component={lazy(() => import('./Generals/Profile'))} />
        <Route path={`${requestedUrl}/task`} component={lazy(() => import('./Generals/Task/TaskList'))} />
        <Route path={`${requestedUrl}/administration/access`} component={lazy(() => import('./Administration/Access'))} />
        <Route
          path={`${requestedUrl}/administration/profiles`}
          component={lazy(() => import('./Administration/Profiles'))}
        />
        <Route
          path={`${requestedUrl}/administration/authorizations/:id/:name`}
          component={lazy(() => import('./Administration/Authorizations'))}
        />
        <Route
          path={`${requestedUrl}/administration/authorizations`}
          component={lazy(() => import('./Administration/Authorizations'))}
        />
        <Route path={`${requestedUrl}/administration/teams`} component={lazy(() => import('./Core/Teams'))} />
        <Route path={`${requestedUrl}/administration/levels-teams`} component={lazy(() => import('./Core/LevelsTeams'))} />
        <Route path={`${requestedUrl}/administration/interactions`} component={lazy(() => import('./Core/Interactions'))} />
        <Route path={`${requestedUrl}/administration/devices`} component={lazy(() => import('./Administration/Devices'))} />
        <Route
          path={`${requestedUrl}/administration/novelties`}
          component={lazy(() => import('./Administration/Novelties'))}
        />

        <Route path={`${requestedUrl}/reports/report-daily`} component={lazy(() => import('./Reports/ReportDaily'))} />
        <Route path={`${requestedUrl}/reports/report-kpi`} component={lazy(() => import('./Reports/ReportKpi'))} />
        <Route path={`${requestedUrl}/reports/tracking`} component={lazy(() => import('./Reports/RealTimeTracking'))} />
        <Route
          path={`${requestedUrl}/reports/extended-tracking-report`}
          component={lazy(() => import('./Reports/ExtendedTrackingReport'))}
        />
        <Route
          path={`${requestedUrl}/reports/managerial-dashboard`}
          component={lazy(() => import('./Reports/ManagerialDashboard'))}
        />
        <Route
          exact
          path={`${requestedUrl}/opportunities/categories`}
          component={lazy(() => import('./Administration/Opportunities/OpportunitiesCategorys'))}
        />
        <Route
          exact
          path={`${requestedUrl}/opportunities/phases`}
          component={lazy(() => import('./Administration/Opportunities/OpportunitiesPhases'))}
        />
        <Route
          exact
          path={`${requestedUrl}/opportunities/types/statuses`}
          component={lazy(() => import('./Administration/Opportunities/OpportunitiesTypes/Statuses'))}
        />
        <Route
          exact
          path={`${requestedUrl}/opportunities/types`}
          component={lazy(() => import('./Administration/Opportunities/OpportunitiesTypes'))}
        />
        <Route
          exact
          path={`${requestedUrl}/administration/opportunities`}
          component={lazy(() => import('./Core/Opportunities'))}
        />

        <Route path={`${requestedUrl}/reports/tracking`} component={lazy(() => import('./Reports/RealTimeTracking'))} />

        <Route
          exact
          path={`${requestedUrl}/reports/opportunities-dashboard`}
          component={lazy(() => import('./Reports/OpportunitiesDashboard/Principal'))}
        />
        <Route
          exact
          path={`${requestedUrl}/reports/opportunities-dashboard/:id/:name`}
          component={lazy(() => import('./Reports/OpportunitiesDashboard/Detail'))}
        />
        <Route
          exact
          path={`${requestedUrl}/reports/campaigns-dashboard`}
          component={lazy(() => import('./Reports/CampaignsDashboard/Principal'))}
        />

        <Route
          exact
          path={`${requestedUrl}/reports/campaigns-dashboard/:id/:name`}
          component={lazy(() => import('./Reports/CampaignsDashboard/Detail'))}
        />

        <Route
          exact
          path={`${requestedUrl}/reports/entities-dashboard`}
          component={lazy(() => import('./Reports/EntitiesDashboard/Principal'))}
        />
        <Route
          exact
          path={`${requestedUrl}/reports/entities-dashboard/:id/:name`}
          component={lazy(() => import('./Reports/EntitiesDashboard/Detail'))}
        />

        <Route
          exact
          path={`${requestedUrl}/reports/teams-dashboard`}
          component={lazy(() => import('./Reports/TeamsDashboard/Principal'))}
        />

        <Route
          exact
          path={`${requestedUrl}/reports/teams-dashboard/:id/:name`}
          component={lazy(() => import('./Reports/TeamsDashboard/Detail'))}
        />
        {/* ROUTES APLICATIONS */}
        <Route path={`${requestedUrl}/calendar`} component={lazy(() => import('./Applications/Calendar'))} />
        <Route path={`${requestedUrl}/support`} component={lazy(() => import('./Utilities/Support'))} />
        {/* END ROUTES APLICATIONS */}
        <Route
          path={`${requestedUrl}/generals/unproductivesTypes`}
          component={lazy(() => import('./Generals/UnproductivesTypes'))}
        />
        <Route
          path={`${requestedUrl}/generals/unproductivesDays`}
          component={lazy(() => import('./Generals/UnproductivesDays'))}
        />
        <Route path={`${requestedUrl}/generals/holidays`} component={lazy(() => import('./Generals/Holidays'))} />
        <Route path={`${requestedUrl}/generals/news`} component={lazy(() => import('./Generals/News'))} />
        <Route
          path={`${requestedUrl}/generals/newsCategories`}
          component={lazy(() => import('./Generals/NewsCategories'))}
        />

        <Route
          path={`${requestedUrl}/utilities/notifications`}
          component={lazy(() => import('./Utilities/Notifications'))}
        />
        <Route path={`${requestedUrl}/utilities/tutorials`} component={lazy(() => import('./Utilities/Tutorials'))} />
        <Route path={`${requestedUrl}/generals/workingStatus`} component={lazy(() => import('./Generals/WorkingStatus'))} />

        <Route path={`${requestedUrl}/log/exceptions`} component={lazy(() => import('./Logs/Exceptions/Principal'))} />
        <Route component={lazy(() => import('../Pages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default App;
