import Alert from 'models/Generals/Alert';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_ALERT, RESET_ALERT } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  initialURL: '/',
  alert: Alert.Reset(),
  error: '',
  message: '',
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: '', loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', loading: false };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload };
    }
    case FETCH_ALERT: {
      return { ...state, loading: false, alert: action.payload };
    }
    case RESET_ALERT: {
      return { ...state, loading: false, alert: Alert.Reset() };
    }
    default:
      return state;
  }
};
