import MainServices from 'services/MainServices';
import { ADD_NOTIFICATION, SET_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  notificationList: null,
  pendingNotifications: Number(localStorage.getItem('pending-notifications') ?? '0'),
  playSound: (localStorage.getItem('play-sound') ?? 'true') === 'true',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      let pendingNotifications = 0;
      if (action.payload?.length > 0) {
        pendingNotifications =
          action.payload.filter(
            notification => notification?.users?.length > 0 && notification?.users[0]?.pivot?.read === 'NO',
          )?.length ?? 0;
      }

      localStorage.setItem('notifications', MainServices.EncryptData(action.payload));
      localStorage.setItem('pending-notifications', pendingNotifications);
      return {
        ...state,
        notificationList: action.payload,
        pendingNotifications: pendingNotifications,
      };
    }
    case ADD_NOTIFICATION: {
      const newNotificationList = state.notificationList && state.notificationList.slice();
      newNotificationList.unshift(action.payload);
      let pendingNotifications = 0;
      if (newNotificationList?.length > 0) {
        pendingNotifications =
          newNotificationList.filter(
            notification => notification?.users?.length > 0 && notification?.users[0]?.pivot?.read === 'NO',
          )?.length ?? 0;
      }
      localStorage.setItem('notifications', MainServices.EncryptData(newNotificationList));
      localStorage.setItem('pending-notifications', pendingNotifications);
      return {
        ...state,
        notificationList: newNotificationList,
        pendingNotifications: pendingNotifications,
      };
    }
    case UPDATE_NOTIFICATIONS: {
      let data = MainServices.EncryptData(action.payload);
      if (data) {
        localStorage.setItem('notifications', data);
      } else {
        localStorage.removeItem('notifications');
      }
      return {
        ...state,
        notificationList: action.payload,
        loadUser: true,
      };
    }
    default:
      return state;
  }
};
