import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_AUTHORIZATIONS,
  UPDATE_NAVIGATIONMENU,
  UPDATE_AUTH_LOGOUT,
  UPDATE_WIDGETS,
  UPDATE_TEAMSTRUCTURE,
  UPDATE_PROFILE_WIDGETS,
} from '@jumbo/constants/ActionTypes';
import MainServices from 'services/MainServices';

const INIT_STATE = {
  authUser: null,
  authorizations: null,
  navigationMenu: null,
  widgets: [],
  profileWidgets: [],
  teamStructure: [],
  loadUser: false,
  send_forget_password_email: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_LOGOUT: {
      MainServices.RemoveCredentialsApp();
      return {
        ...state,
        authUser: null,
        loadUser: true,
        authorizations: null,
        navigationMenu: null,
        teamStructure: null,
      };
    }
    case UPDATE_AUTH_USER: {
      let data = MainServices.EncryptData(action.payload);
      if (data) {
        localStorage.setItem('user', data);
      } else {
        localStorage.removeItem('user');
      }
      return {
        ...state,
        authUser: action.payload,
        loadUser: true,
      };
    }
    case UPDATE_AUTHORIZATIONS: {
      let data = MainServices.EncryptData(action.payload);
      if (data) {
        localStorage.setItem('authorizations', data);
      } else {
        localStorage.removeItem('authorizations');
      }
      return {
        ...state,
        authorizations: action.payload,
      };
    }
    case UPDATE_NAVIGATIONMENU: {
      let data = MainServices.EncryptData(action.payload);
      if (data) {
        localStorage.setItem('navigationMenu', data);
      } else {
        localStorage.removeItem('navigationMenu');
      }
      return {
        ...state,
        navigationMenu: action.payload,
      };
    }
    case UPDATE_WIDGETS: {
      let data = MainServices.EncryptData(action.payload);
      if (data) {
        localStorage.setItem('widgets', data);
      } else {
        localStorage.removeItem('widgets');
      }
      return {
        ...state,
        widgets: action.payload,
      };
    }
    case UPDATE_TEAMSTRUCTURE: {
      let data = MainServices.EncryptData(action.payload);
      if (data) {
        localStorage.setItem('teamStructure', data);
      } else {
        localStorage.removeItem('teamStructure');
      }
      return {
        ...state,
        teamStructure: action.payload,
      };
    }
    case UPDATE_PROFILE_WIDGETS: {
      let data = MainServices.EncryptData(action.payload);
      if (data) {
        localStorage.setItem('profileWidgets', data);
      } else {
        localStorage.removeItem('profileWidgets');
      }
      return {
        ...state,
        profileWidgets: action.payload,
      };
    }
    case SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload,
      };
    }
    default:
      return state;
  }
};
