import DynamicIconReact from '@aura/DynamicIconReact';
import { fade, IconButton, Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Alert from 'models/Generals/Alert';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchAlert, fetchStart, fetchSuccess } from 'redux/actions';
import UserService from 'services/Admin/UserService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CmtImage from '../../../../@coremat/CmtImage';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import IntlMessages from '../../../utils/IntlMessages';
import ContentLoader from '../../ContentLoader';
import AuthWrapper from './AuthWrapper';
import ModalCodeVerification from './ModalCodeVerification';

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
  headerRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [changePassword, setChangePassword] = useState(false);
  const [password, setPassword] = useState(null);
  const [code, setCode] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verifiedUser, setVerifiedUser] = useState(null);
  const [userMessageError, setUserMessageError] = useState(null);
  const [email, setEmail] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  function forgotPassword() {
    if (validateFormEmail()) {
      setUserMessageError();
      let parameters = { email: email, type: 'PASSWORD_RECOVERY' };
      dispatch(fetchStart());
      setLoading(true);
      UserService.postForgotPassword(parameters).then(response => {
        dispatch(fetchSuccess());
        setLoading(false);
        dispatch(fetchAlert(Alert.Set(response.satisfactory, response.title, response.message)));
        if (response.satisfactory) {
          setVerifiedUser({ user: response?.data, openModalCode: true });
          return;
        }
        sweetAlertsListError(response.title, response.messages);
      });
    }
  }

  const onHandleSuccesVerification = code => {
    handleCloseModalCode();
    setChangePassword(true);
    setPassword('');
    setConfirmPassword('');
    setCode(code);
  };

  const onSubmit = () => {
    if (validateFormChangePassword()) {
      setUserMessageError();
      let parameters = { email: email, new_password: password, confirm_password: confirmPassword, code: code };
      dispatch(fetchStart());
      UserService.postPasswordRecovery(parameters).then(response => {
        dispatch(fetchAlert(Alert.Set(response.satisfactory, response.title, response.message)));
        dispatch(fetchSuccess());
        if (response.satisfactory) {
          history.push('/login');
          return;
        }
        sweetAlertsListError(response.title, response.messages);
      });
    }
  };

  const handleCloseModalCode = () => {
    setVerifiedUser({ openModalCode: false });
  };

  const validateFormChangePassword = () => {
    let validForm = true;
    let userMessageError = {};
    if (!password || password === '') {
      userMessageError.password = 'Es requerido una contraseña.';
      validForm = false;
    }
    if (!confirmPassword || confirmPassword === '') {
      userMessageError.confirmPassword = 'Es requerido confirmar contraseña.';
      validForm = false;
    }
    if (password && confirmPassword && password !== confirmPassword) {
      userMessageError.confirmPassword = 'Las contraseñas no coinciden.';
      validForm = false;
      dispatch(fetchAlert(Alert.Set(false, 'DATOS FALTANTES', 'Las contraseñas no coinciden.')));
      return validForm;
    }
    if (!validForm) {
      setUserMessageError(userMessageError);
      dispatch(fetchAlert(Alert.Set(false, 'DATOS FALTANTES', 'Completar el formulario')));
    }
    return validForm;
  };

  const validateFormEmail = () => {
    let validForm = true;
    let userMessageError = {};
    if (!email || email === '') {
      userMessageError.email = 'Es requerido el email.';
      validForm = false;
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        userMessageError.email = 'El formato del email es incorrecto.';
        validForm = false;
      }
    }
    if (!validForm) {
      setUserMessageError(userMessageError);
      dispatch(fetchAlert(Alert.Set(false, 'DATOS FALTANTES', 'Completar el formulario')));
    }
    return validForm;
  };

  const handleReturn = () => {
    history.goBack();
  };

  const handleInfoContact = () => {
    let contentHtml =
      '<br><i class="fas fa-envelope" style="margin-right: 4px;"></i> info@mslarco.com<br><br><i class="fas fa-phone" style="margin-right: 4px;"></i> +593 97 912 9574';
    MySwal.fire({
      icon: 'info',
      title: `Datos de contacto`,
      html: contentHtml,
    });
  };

  //#region SweetAlert
  const sweetAlertsListError = (title, messages) => {
    if (Array.isArray(messages) && messages.length > 0) {
      MySwal.fire({
        icon: 'error',
        title: `${title}!`,
        html: messages.join('<br>'),
      });
    }
  };
  //#endregion

  useEffect(() => {
    return () => {};
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePassword]);

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      {changePassword ? (
        <div className={classes.authContent}>
          <div className={clsx('mb-7', classes.headerRoot)}>
            <CmtImage src={'/images/logo.png'} style={{ width: '150px' }} />
            <IconButton onClick={handleReturn.bind(this)}>
              <DynamicIconReact name="IoArrowBack" size="md" />
            </IconButton>
          </div>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            Recuperación de contraseña
          </Typography>
          <div className={'mb-5'}>
            <TextField
              id="password"
              name="password"
              autoComplete={false}
              label={<IntlMessages id="appModule.newPassword" />}
              type="password"
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password ?? ''}
              value={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={userMessageError?.password}
              helperText={userMessageError?.password}
            />
            <TextField
              id="confirm-password"
              name="confirm-password"
              autoComplete={false}
              label={<IntlMessages id="appModule.confirmPassword" />}
              type="password"
              fullWidth
              onChange={event => setConfirmPassword(event.target.value)}
              defaultValue={confirmPassword ?? ''}
              value={confirmPassword ?? ''}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={userMessageError?.confirmPassword}
              helperText={userMessageError?.confirmPassword}
            />
          </div>
          <div className={'mb-5'}>
            <Button onClick={onSubmit.bind(this)} variant="contained" color="primary">
              <IntlMessages id="appModule.savePassword" />
            </Button>
          </div>
          <ContentLoader />
        </div>
      ) : (
        <div className={classes.authContent}>
          <div className={clsx('mb-7', classes.headerRoot)}>
            <CmtImage src={'/images/logo.png'} style={{ width: '150px' }} />
            <IconButton onClick={handleReturn.bind(this)}>
              <DynamicIconReact name="IoArrowBack" size="md" />
            </IconButton>
          </div>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            Has olvidado tu contraseña
          </Typography>
          <div className={'mb-5'}>
            <TextField
              id="email"
              name="email"
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              defaultValue={email ?? ''}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={userMessageError?.email}
              helperText={userMessageError?.email}
            />
          </div>
          <div className={'mb-5'}>
            <Button onClick={forgotPassword.bind(this)} variant="contained" color="primary" disabled={loading}>
              <IntlMessages id="appModule.resetPassword" />
            </Button>
          </div>

          <div>
            <Typography>
              ¿No recuerdo correo electrónico?
              <span className={'ml-2'}>
                <Link href="#" onClick={handleInfoContact.bind(this)}>
                  <IntlMessages id="appModule.contactSupport" />
                </Link>
              </span>
            </Typography>
          </div>
          <ContentLoader />
        </div>
      )}

      {verifiedUser?.openModalCode && (
        <ModalCodeVerification
          openModalCode={verifiedUser?.openModalCode}
          user={verifiedUser?.user}
          method={method}
          onSubmit={onHandleSuccesVerification.bind(this)}
          typeCode="PASSWORD_RECOVERY"
        />
      )}
    </AuthWrapper>
  );
};

export default ForgotPassword;
