import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  Toolbar,
} from '@material-ui/core';
import Alert from 'models/Generals/Alert';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAlert } from 'redux/actions';
import UserService from 'services/Admin/UserService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import useStyles from './index.style';

const MySwal = withReactContent(Swal);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalCodeVerification = ({ user, openModalCode, onSubmit, typeCode }) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [userMessageError, setUserMessageError] = useState(null);

  function sendVerificationCode() {
    let parameters = { type: typeCode };
    setLoading(true);
    UserService.postSendValidationCode(user?.id, parameters).then(response => {
      dispatch(fetchAlert(Alert.Set(response.satisfactory, response.title, response.message)));
      setLoading(false);
      if (response.satisfactory) {
        return;
      }
      sweetAlertsListError(response.title, response.messages);
    });
  }

  function verificationCode() {
    if (validateFormChangePassword()) {
      let parameters = { type: typeCode, code: code };
      setLoading(true);
      UserService.postVerificationCode(user?.id, parameters).then(response => {
        dispatch(fetchAlert(Alert.Set(response.satisfactory, response.title, response.message)));
        setLoading(false);
        if (response.satisfactory) {
          onSubmit(code);
          return;
        }
        setCode(null);
        sweetAlertsListError(response.title, response.messages);
      });
    }
  }

  const validateFormChangePassword = () => {
    let validForm = true;
    let userMessageError = {};
    if (!code || code === '') {
      userMessageError.code = 'Es requerido el código.';
      validForm = false;
    }
    if (!validForm) {
      setUserMessageError(userMessageError);
      dispatch(fetchAlert(Alert.Set(false, 'DATOS FALTANTES', 'Completar el formulario')));
    }
    return validForm;
  };

  //#region SweetAlert
  const sweetAlertsListError = (title, messages) => {
    if (Array.isArray(messages) && messages.length > 0) {
      MySwal.fire({
        icon: 'error',
        title: `${title}!`,
        html: messages.join('<br>'),
      });
    }
  };
  //#endregion

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={openModalCode}
      aria-labelledby="max-width-dialog-title"
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <DialogTitle id="max-width-dialog-title" className={classes.title}>
            VERIFICACIÓN DE CÓDIGO {loading && <CircularProgress size={18} className={classes.loading} />}
          </DialogTitle>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.contentRoot}>
        <Box component="p" color="text.primary">
          Por su seguridad debe confirmar el código que se enviará al correo electrónico registrado.
        </Box>
        <Box className={classes.boxInputCode}>
          <TextField
            id="outlined-basic"
            className={classes.textCodeRoot}
            variant="filled"
            margin="dense"
            size="medium"
            placeholder="XXXXX"
            defaultValue={code ?? ''}
            value={code ?? ''}
            onChange={event => {
              setCode(event.target.value);
            }}
            fullWidth
            InputProps={{
              className: classes.inputCodeRoot,
            }}
            error={userMessageError?.code}
            helperText={userMessageError?.code}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.buttonsActionRoot}>
        <Button
          className={classes.buttonActions}
          onClick={verificationCode.bind(this)}
          variant="contained"
          color="primary"
          disabled={loading || !code}
          fullWidth>
          CONFIRMAR
        </Button>
        <Button
          className={classes.buttonActions}
          onClick={sendVerificationCode.bind(this)}
          variant="contained"
          color="default"
          disabled={loading}
          fullWidth>
          ENVIAR NUEVAMENTE EL CÓDIGO
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCodeVerification;
