import CmtImage from '@coremat/CmtImage';
import {
  AppBar,
  Container,
  Fab,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  useScrollTrigger,
  Zoom,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import DynamicIconReact from '@aura/DynamicIconReact';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

const TermsConditions = props => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
              <CmtImage src={'/images/logo-white.png'} style={{ width: '150px' }} />
            </IconButton>
            <Typography variant="h1" color="inherit" component="div">
              TÉRMINOS Y CONDICIONES
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Container style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Paper variant="elevation" style={{ padding: '1rem', textAlign: 'justify' }}>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '8.0pt',
              marginLeft: '0cm',
              lineHeight: '107%',
              fontSize: '24px',
              fontFamily: 'Calibri sans-serif',
              fontStretch: 'semi-expanded',
              textAlign: 'center',
            }}>
            <strong>
              CONDICIONES DE USO Y POL&Iacute;TICA DE PRIVACIDAD PARA APLICACIONES M&Oacute;VILES Y ACCESO DESDE DISPOSITIVOS
              M&Oacute;VILES
            </strong>
          </p>
          <Box style={{ marginLeft: '2rem', marginRight: '2rem', textAlign: 'justify' }}>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '17px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>1. CONDICIONES GENERALES DE USO</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              El presente documento tiene por objeto establecer las Condiciones Generales de Uso de las aplicaciones
              m&oacute;viles titularidad de MSLARCO DESARROLLO S.A.S. (en adelante MSLARCO DESARROLLO) con domicilio social
              en la ciudad de Quito, y bajo Ruc: 1793168183001.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              MSLARCO DESARROLLO se reserva el derecho a modificar las presentes Condiciones de Uso con el objeto de
              adecuarlas a la legislaci&oacute;n vigente aplicable en cada momento.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              Las presentes Condiciones de Uso no excluyen la posibilidad de que determinados Servicios de las aplicaciones,
              por sus caracter&iacute;sticas particulares, sean sometidos, adem&aacute;s de a las Condiciones Generales de
              Uso, a sus propias condiciones particulares de uso (en adelante las Condiciones Particulares).
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              La Empresa podr&aacute;, en cualquier momento y sin necesidad de previo aviso, realizar cambios y
              actualizaciones de las presentes Condiciones de Uso y de la Pol&iacute;tica de Privacidad. Estos cambios
              ser&aacute;n publicados en la Web y en la/s Aplicaci&oacute;n/es y ser&aacute;n efectivos desde el momento de
              su publicaci&oacute;n.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              Como consecuencia de lo anterior, el Usuario deber&aacute; revisar peri&oacute;dicamente si hay cambios en
              estas Condiciones y, tanto si existe consentimiento expreso como si no, si el usuario contin&uacute;a usando el
              Servicio tras la publicaci&oacute;n, ello implica la aceptaci&oacute;n y asunci&oacute;n de los mismos. En caso
              de que no est&eacute; de acuerdo con las actualizaciones de las Condiciones de uso o de la Pol&iacute;tica de
              Privacidad, podr&aacute; renunciar dejando de usar el Servicio.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              El acceso y descarga de la aplicaci&oacute;n es gratuito salvo en lo relativo al coste de la conexi&oacute;n a
              trav&eacute;s de la red de telecomunicaciones suministrada por el proveedor de acceso contratado por los
              usuarios. Determinados servicios son exclusivos para nuestros clientes y su acceso se encuentra restringido.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              La descarga y uso de la aplicaci&oacute;n atribuye la condici&oacute;n de usuario de la misma (en adelante, el
              &lsquo;Usuario&apos;) e implica la lectura, entendimiento y aceptaci&oacute;n de todos los t&eacute;rminos y
              condiciones recogidas por la presente.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '17px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>1.1) USO DE LA APLICACI&Oacute;N M&Oacute;VIL Y SUS SERVICIOS</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              El Usuario reconoce y acepta que el uso de los contenidos y/o servicios ofrecidos por la presente
              aplicaci&oacute;n m&oacute;vil ser&aacute; bajo su exclusivo riesgo y/o responsabilidad. El Usuario se
              compromete a utilizar la presente aplicaci&oacute;n m&oacute;vil y todo su contenido y Servicios de conformidad
              con la ley, la moral, el orden p&uacute;blico y las presentes Condiciones de Uso, y las Condiciones
              Particulares que, en su caso, le sean de aplicaci&oacute;n. As&iacute; mismo, se compromete hacer un uso
              adecuado de los servicios y/o contenidos de la aplicaci&oacute;n m&oacute;vil y a no emplearlos para realizar
              actividades il&iacute;citas o constitutivas de delito, que atenten contra los derechos de terceros y/o que
              infrinjan la regulaci&oacute;n sobre propiedad intelectual e industrial, o cualesquiera otras normas del
              ordenamiento jur&iacute;dico aplicable. En particular, el Usuario se compromete a no trasmitir, introducir,
              difundir y poner a disposici&oacute;n de terceros, cualquier tipo de material e informaci&oacute;n (datos
              contenidos, mensajes, dibujos, archivos de sonido e imagen, fotograf&iacute;as, software, etc.) que sean
              contrarios a la ley, la moral, el orden p&uacute;blico y las presentes Condiciones de Uso y, en su caso, a las
              Condiciones Particulares que le sean de aplicaci&oacute;n. A t&iacute;tulo enunciativo, y en ning&uacute;n caso
              limitativo o excluyente, el Usuario se compromete a:
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              No introducir o difundir contenidos o propaganda de car&aacute;cter racista, xen&oacute;fobo,
              pornogr&aacute;fico, de apolog&iacute;a del terrorismo o que atenten contra los derechos humanos.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              No introducir o difundir en la red programas de datos (virus y software nocivo) susceptibles de provocar
              da&ntilde;os en los sistemas inform&aacute;ticos del proveedor de acceso, sus proveedores o terceros usuarios
              de la red Internet.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              No introducir o difundir en la red programas de datos (virus y software nocivo) susceptibles de provocar
              da&ntilde;os en los sistemas inform&aacute;ticos del proveedor de acceso, sus proveedores o terceros usuarios
              de la red Internet.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              No difundir, transmitir o poner a disposici&oacute;n de terceros cualquier tipo de informaci&oacute;n, elemento
              o contenido que atente contra los derechos fundamentales y las libertades p&uacute;blicas reconocidos
              constitucionalmente y en los tratados internacionales.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              No difundir, transmitir o poner a disposici&oacute;n de terceros cualquier tipo de informaci&oacute;n, elemento
              o contenido que constituya publicidad il&iacute;cita o desleal.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              No transmitir publicidad no solicitada o autorizada, material publicitario, &quot;correo basura&quot;,
              &quot;cartas en cadena&quot;, &quot;estructuras piramidales&quot;, o cualquier otra forma de
              solicitaci&oacute;n, excepto en aquellas &aacute;reas (tales como espacios comerciales) que hayan sido
              exclusivamente concebidas para ello.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              No introducir o difundir cualquier informaci&oacute;n y contenidos falsos, ambiguos o inexactos de forma que
              induzca a error a los receptores de la informaci&oacute;n.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              No suplantar a otros usuarios utilizando sus claves de registro a los distintos servicios y/o contenidos de los
              Portales.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              No difundir, transmitir o poner a disposici&oacute;n de terceros cualquier tipo de informaci&oacute;n, elemento
              o contenido que suponga una violaci&oacute;n de los derechos de propiedad intelectual e industrial, patentes,
              marcas o copyright que correspondan a los titulares de los Portales o a terceros.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              No difundir, transmitir o poner a disposici&oacute;n de terceros cualquier tipo de informaci&oacute;n, elemento
              o contenido que suponga una violaci&oacute;n del secreto de las comunicaciones y la legislaci&oacute;n de datos
              de car&aacute;cter personal.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '17px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>1.2) PROPIEDAD INTELECTUAL E INDUSTRIAL</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              MSLARCO DESARROLLO se reserva todos los derechos sobre las marcas, nombres comerciales o dem&aacute;s signos
              distintivos, las patentes, y la propiedad intelectual, con respecto al contenido y dise&ntilde;o de la
              aplicaci&oacute;n. Todos los contenidos de la misma, incluyendo sin car&aacute;cter limitativo, textos,
              gr&aacute;ficos, im&aacute;genes, su dise&ntilde;o y los derechos de propiedad intelectual que pudieran
              corresponder a dichos contenidos, as&iacute; como las marcas, nombres comerciales o cualquier otro signo
              distintivo son propiedad de MSLARCO DESARROLLO quedando reservados todos los derechos sobre los mismos. Las
              marcas, nombres comerciales o signos distintivos son titularidad de MSLARCO DESARROLLO sin que pueda entenderse
              que la descarga, acceso y uso de la aplicaci&oacute;n AURA-CRM le atribuya ning&uacute;n derecho sobre las
              citadas marcas, nombres comerciales y/o signos distintivos.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '17px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>1.3) EXCLUSION DE GARANT&Iacute;AS. RESPONSABILIDAD</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              MSLARCO DESARROLLO no garantiza en todo momento la disponibilidad de acceso y continuidad del funcionamiento de
              la presente aplicaci&oacute;n m&oacute;vil y de sus servicios, por lo que MSLARCO DESARROLLO no ser&aacute;
              responsable, con los l&iacute;mites establecidos en el Ordenamiento Jur&iacute;dico vigente, de los
              da&ntilde;os y perjuicios causados al Usuario como consecuencia de la indisponibilidad, fallos de acceso y
              falta de continuidad de la presente aplicaci&oacute;n m&oacute;vil y sus Servicios.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              MSLARCO DESARROLLO responder&aacute; &uacute;nica y exclusivamente de los Servicios que preste por s&iacute;
              misma y de los contenidos directamente originados por la empresa e identificados con su copyright. Dicha
              responsabilidad quedar&aacute; excluida en los casos en que concurran causas de fuerza mayor o en los supuestos
              en que la configuraci&oacute;n de los dispositivos del Usuario no sea la adecuada para permitir el correcto uso
              de los servicios prestados por MSLARCO DESARROLLO.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              La descarga, acceso y uso de la aplicaci&oacute;n en los dispositivos m&oacute;viles o similares, no implica la
              obligaci&oacute;n por parte de MSLARCO DESARROLLO de controlar la ausencia de virus, gusanos o cualquier otro
              elemento inform&aacute;tico da&ntilde;ino. Corresponde al Usuario, en todo caso, la disponibilidad de
              herramientas adecuadas para la detecci&oacute;n y desinfecci&oacute;n de programas inform&aacute;ticos
              da&ntilde;inos.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '17px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>1.4) CONDUCTA DE LOS USUARIOS</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              MSLARCO DESARROLLO no garantiza que los Usuarios de la presente aplicaci&oacute;n m&oacute;vil AURA-CRM
              utilicen los contenidos y/o servicios del mismo de conformidad con la ley, la moral, el orden p&uacute;blico,
              ni las presentes Condiciones Generales y, en su caso, las condiciones Particulares que resulten de
              aplicaci&oacute;n. As&iacute; mismo, no garantiza la veracidad y exactitud, exhaustividad y/o autenticidad de
              los datos proporcionados por los Usuarios.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              MSLARCO DESARROLLO no ser&aacute; responsable, indirecta ni subsidiariamente, de los da&ntilde;os y perjuicios
              de cualquier naturaleza derivados de la utilizaci&oacute;n de los Servicios y Contenidos de la
              aplicaci&oacute;n por parte de los Usuarios o que puedan derivarse de la falta de veracidad, exactitud y/o
              autenticidad de los datos o informaciones proporcionadas por los Usuarios, o de la suplantaci&oacute;n de la
              identidad de un tercero efectuada por un Usuario en cualquier clase de actuaci&oacute;n a trav&eacute;s de la
              presente aplicaci&oacute;n m&oacute;vil AURA-CRM. Por lo tanto, el uso de esta aplicaci&oacute;n no implica la
              obligaci&oacute;n por parte de MSLARCO DESARROLLO de comprobar la veracidad, exactitud, adecuaci&oacute;n,
              idoneidad, exhaustividad y actualidad de la informaci&oacute;n suministrada a trav&eacute;s de la misma.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              MSLARCO DESARROLLO no se responsabiliza de las decisiones tomadas a partir de la informaci&oacute;n
              suministrada a trav&eacute;s de la aplicaci&oacute;n ni de los da&ntilde;os y perjuicios producidos en el
              Usuario o terceros con motivo de actuaciones que tengan como &uacute;nico fundamento la informaci&oacute;n
              obtenida en la aplicaci&oacute;n.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '17px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>2. POLITICA PRIVACIDAD Y USO DE DATOS</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              Todos los datos que se solicitan a los usuarios y clientes a trav&eacute;s de la aplicaci&oacute;n para
              dispositivos m&oacute;viles ser&aacute;n necesarios para prestar el servicio objeto del servicio en virtud del
              cual se ha procedido a la descarga e instalaci&oacute;n de la/s aplicaci&oacute;n/es en los correspondientes
              dispositivos.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '17px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>2.1) CONSENTIMIENTO</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              La utilizaci&oacute;n de la aplicaci&oacute;n dar&aacute; lugar al tratamiento de datos de car&aacute;cter
              personal que MSLARCO DESARROLLO, en su caso, llevar&aacute; a cabo de acuerdo con las normas y procedimientos
              internos establecidos al efecto, que son conocidos por los clientes y usuarios y autorizados por &eacute;stos.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '17px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>2.2) GARANT&Iacute;A Y PROTECCI&Oacute;N DE DATOS</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              En el tratamiento de los datos de car&aacute;cter personal, MSLARCO DESARROLLO se compromete a garantizar y
              proteger las libertades p&uacute;blicas y los derechos fundamentales de las personas f&iacute;sicas de los
              archivos y, especialmente, su honor y su intimidad familiar y personal, oblig&aacute;ndose en este sentido, a
              efectuar el correspondiente tratamiento de datos de acuerdo con la normativa vigente en cada momento y a
              guardar el m&aacute;s absoluto secreto en relaci&oacute;n con la informaci&oacute;n entregada por los clientes
              y usuarios. Los datos de car&aacute;cter personal objeto de tratamiento no se utilizar&aacute;n para otras
              finalidades que no se encuentren aqu&iacute; recogidas o, en su caso, por otro documento o contrato que
              vinculase a ambas partes con condiciones particulares, ning&uacute;n dato de informaci&oacute;n ser&aacute;
              compartido en ninguno momento.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '17px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>3.1) MODIFICACIONES</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              MSLARCO DESARROLLO se reserva el derecho a efectuar las modificaciones que estime oportunas, pudiendo
              modificar, suprimir e incluir nuevos contenidos y/o servicios, as&iacute; como la forma en que &eacute;stos
              aparezcan presentados y localizados.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '17px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>3.2) MENORES DE EDAD</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              Con car&aacute;cter general, para hacer uso de los Servicios de la presente aplicaci&oacute;n m&oacute;vil
              AURA-CRM, los menores de edad no forman parte del p&uacute;blico objetivo, el acceso quedar&aacute; restringido
              &uacute;nica y exclusivamente a mayores de 18 a&ntilde;os.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>3.3) DURACI&Oacute;N Y TERMINACI&Oacute;N</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              La prestaci&oacute;n de los servicios y/o contenidos de la presente aplicaci&oacute;n m&oacute;vil tiene una
              duraci&oacute;n indefinida. Sin perjuicio de lo anterior, MSLARCO DESARROLLO est&aacute; facultada para dar por
              terminada, suspender o interrumpir unilateralmente, en cualquier momento y sin necesidad de preaviso, la
              prestaci&oacute;n del servicio y de la presente aplicaci&oacute;n m&oacute;vil y/o de cualquiera de los
              servicios, sin perjuicio de lo que se hubiera dispuesto al respecto en las correspondientes condiciones
              particulares.
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              &nbsp;
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '17px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              <strong>3.4) LEY Y JURISDICCI&Oacute;N</strong>
            </p>
            <p
              style={{
                marginTop: '0cm',
                marginRight: '0cm',
                marginBottom: '8.0pt',
                marginLeft: '0cm',
                lineHeight: '107%',
                fontSize: '16px',
                fontFamily: 'Calibri sans-serif',
                fontStretch: 'semi-expanded',
              }}>
              Las cuestiones relativas a las Condiciones de Uso y Pol&iacute;tica de Privacidad, as&iacute; como todas
              aquellas cuestiones que radican y tengan relaci&oacute;n en parte o en su totalidad con los servicios
              suministrados a trav&eacute;s de la aplicaci&oacute;n, se rigen en todos y cada uno de sus extremos por la ley
              Ecuatoriana renunciando expresamente las partes al fuero que les corresponda, y someti&eacute;ndose a los
              Juzgados y Tribunales de la ciudad de Quito.
            </p>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default TermsConditions;
