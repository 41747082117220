import qs from 'qs';
import GeneralsHelper from '../helpers/GeneralsHelper';
import socketio from 'socket.io-client';
import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';

class MainServices {
  static KEY_ENCRIPT_STORAGE = 'GsXziOutZNflysF1sJLsTQv/2AxJAUtXbABjCJonw5Q=';
  static KEY_GOOGLE_MAP = 'AIzaSyAFZbuaSYS4miJI3Js77n1tj6xUVXYq14M';
  static URL_GOOGLE_MAP = `https://maps.googleapis.com/maps/api/js?key=${this.KEY_GOOGLE_MAP}&libraries=places&libraries=drawing`;
  static SEPARADOR = '/';

  static HOST_ARRAY =
    window.location.host && window.location.host.search('localhost') < 0 ? window.location.host.split('.') : ['localhost'];
  static IS_PROD = this.HOST_ARRAY?.length > 1;
  static HOST_CURRENT = `${
    this.IS_PROD ? `${this.HOST_ARRAY[0]}-backend.${this.HOST_ARRAY[1]}.${this.HOST_ARRAY[2]}` : this.HOST_ARRAY[0]
  }`;
  static URL_SERVER = `${window.location.protocol}//${this.HOST_CURRENT}/${
    this.IS_PROD ? 'public/' : 'aura-crm/aura-crm-backend/public/'
  }`;
  static URL_LARAVEL_ECHO_SERVER = `${window.location.protocol}//${this.HOST_CURRENT}:6001`;

  // static URL_SERVER = 'https://bg-backend.aura-crm.com/public/';
  // static URL_LARAVEL_ECHO_SERVER = 'http://bg-backend.aura-crm.com:6001';

  static API_PATH = this.URL_SERVER + 'api/';
  static MODULO_PATH = 'modulo/';

  //#region CONFIGURATION OPTIONS FOR SOCKETS
  static echoConfiguration = {
    broadcaster: 'socket.io',
    host: MainServices.URL_LARAVEL_ECHO_SERVER,
    wssHost: MainServices.URL_LARAVEL_ECHO_SERVER,
    wssPort: 6001,
    disableStats: true,
    client: socketio,
    transports: ['websocket'],
  };
  //#endregion

  static getApiBaseURL(baseNombre, modeloNombre, modeloId, funcionesExtras, parametros) {
    return (
      this.API_PATH +
      baseNombre +
      this.SEPARADOR +
      modeloNombre +
      (modeloId > 0 ? this.SEPARADOR + modeloId : '') +
      (funcionesExtras !== null ? this.SEPARADOR + funcionesExtras : '') +
      (parametros !== null ? '?' + qs.stringify(parametros) : '')
    );
  }

  static getModuloURLAdminwithFunction(modulo, modeloName, modeloId, funcionName, parametros) {
    return (
      this.API_PATH +
      modulo +
      this.SEPARADOR +
      modeloName +
      (modeloId > 0 ? this.SEPARADOR + modeloId : '') +
      this.SEPARADOR +
      funcionName +
      (parametros !== null ? '?' + qs.stringify(parametros) : '')
    );
  }

  static getModuloAdminURL(modulo, modeloName, modeloId, parametros) {
    return (
      this.API_PATH +
      modulo +
      this.SEPARADOR +
      modeloName +
      (modeloId > 0 ? this.SEPARADOR + modeloId : '') +
      (parametros !== null ? '?' + qs.stringify(parametros) : '')
    );
  }

  static getAdminURL(recurso) {
    return this.API_PATH + 'admin/' + recurso;
  }

  static processResponse(success, respuesta, error) {
    if (!success) {
      if (GeneralsHelper.isset(() => error.response) && GeneralsHelper.isset(() => error.response.data)) {
        respuesta = error.response.data;
      } else {
        respuesta = {
          satisfactory: false,
          title: error.message,
          message: error.message,
          data: null,
          code: error.status,
        };
      }
    }

    return respuesta;
  }

  static getFiltrosCruds(filtros) {
    let Filtros = [];

    filtros.map((filtro, index) => {
      if (filtro.valor !== '') {
        let datosFiltro = {
          nombreCampo: filtro.nombreCampo,
          tipoBusqueda: filtro.tipoBusqueda,
          valor: filtro.valor,
        };

        Filtros.push(datosFiltro);
      }
      return filtro;
    });

    return Filtros;
  }

  //#region Methods Security APP
  static RemoveCredentialsApp() {
    localStorage.removeItem('user');
    localStorage.removeItem('authorizations');
    localStorage.removeItem('navigationMenu');
    localStorage.removeItem('notifications');
    localStorage.removeItem('widgets');
    localStorage.removeItem('profileWidgets');
    localStorage.removeItem('teamStructure');
    localStorage.removeItem('pending-notifications');
  }

  static EncryptData(textToEncrypt) {
    let textEncrypt = null;
    try {
      if (textToEncrypt && typeof textToEncrypt === 'object') {
        textEncrypt = AES.encrypt(JSON.stringify(textToEncrypt), MainServices.KEY_ENCRIPT_STORAGE);
      }
      if (textToEncrypt && typeof textToEncrypt === 'string') {
        textEncrypt = AES.encrypt(textToEncrypt, MainServices.KEY_ENCRIPT_STORAGE);
      }
    } catch (error) {
      textEncrypt = null;
    }
    return textEncrypt;
  }

  static DecryptData(textEncrypted) {
    let textDecrypt = null;
    try {
      textDecrypt = AES.decrypt(textEncrypted, MainServices.KEY_ENCRIPT_STORAGE).toString(encUtf8);
      if (GeneralsHelper.IsJsonString(textDecrypt)) {
        textDecrypt = JSON.parse(textDecrypt);
      }
    } catch (error) {
      textDecrypt = null;
    }
    return textDecrypt;
  }
  //#endregion
}

export default MainServices;
