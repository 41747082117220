import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './fontawesome';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/common/main.css';
import UnsecuredPage from './UnsecuredPage';

if (window.self === window.top) {
  ReactDOM.render(<App />, document.getElementById('root'));
} else {
  ReactDOM.render(<UnsecuredPage />, document.getElementsByClassName('app')[0]);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
