import axios from 'axios';
import MainServices from '../MainServices';

let UserService = {
  //#region Basic Api Resources
  getIndex: parameters => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', null, null, parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  postStore: formData => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', null, null, null), formData)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  putUpdate: formData => {
    return axios
      .put(MainServices.getApiBaseURL('admin', 'users', formData?.id, null, null), formData)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  deleteDestroy: userId => {
    return axios
      .delete(MainServices.getApiBaseURL('admin', 'users', userId, null, null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  //#endregion

  //#region Methods
  getUsers: () => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', null, 'getUserList', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  getAppStatistics: parameters => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', null, 'getAppStatistics', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  postUserLogin: parameters => {
    let $urlLogin = MainServices.getApiBaseURL('admin', 'users', null, 'loginApp', null);
    if (parameters['username_admin'] && parameters['username_admin'] !== '') {
      $urlLogin = MainServices.getApiBaseURL('admin', 'users', null, 'loginSuperAdmin', null);
    }
    return axios
      .post($urlLogin, parameters)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  postCredentialsVerification: parameters => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', null, 'credentialsVerification', null), parameters)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  postSendValidationCode: (userId, parameters) => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', userId, 'sendValidationCode', null), parameters)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  postVerificationCode: (userId, parameters) => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', userId, 'verificationCode', null), parameters)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  postForgotPassword: parameters => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', null, 'forgotPassword', null), parameters)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  postPasswordRecovery: parameters => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', null, 'passwordRecovery', null), parameters)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  postUserLogout: () => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', null, 'logout', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  updateAuthorizations: userId => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', userId, 'updateAuthorizations', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  userAuthInformation: () => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', null, 'userAuthInformation', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  updatePassword: formData => {
    return axios
      .put(MainServices.getApiBaseURL('admin', 'users', null, 'updatePassword', formData))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  updateInformation: formData => {
    return axios
      .put(MainServices.getApiBaseURL('admin', 'users', null, 'updateInformation', formData))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  updatePhotographType: formData => {
    return axios
      .put(MainServices.getApiBaseURL('admin', 'users', null, 'updatePhotographType', null), formData)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getDayLocations: (userId, parameters) => {
    return axios
      .get(MainServices.getModuloURLAdminwithFunction('admin', 'users', userId, 'getDayLocations', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getTopUserListInteractions: parameters => {
    return axios
      .post(
        MainServices.getModuloURLAdminwithFunction('admin', 'users', null, 'getTopUserListInteractions', null),
        parameters,
      )
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getUserListSummaryEffectiveness: parameters => {
    return axios
      .post(
        MainServices.getModuloURLAdminwithFunction('admin', 'users', null, 'getUserListSummaryEffectiveness', parameters),
      )
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  adminEditUserPassword: formData => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', null, 'adminEditUserPassword', null), formData)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  getWidgetsBySection: (userId, parameters) => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', userId, 'getWidgetsBySection', null), parameters)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  configureWidgetsSection: (userId, parameters) => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', userId, 'configureWidgetsSection', null), parameters)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  updateAllAccess: () => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', null, 'updateAllAccess', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  updateAllWidgets: () => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', null, 'updateAllWidgets', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  updateAccessWidgets: (userId, parameters) => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', userId, 'updateAccessWidgets', null), parameters)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  reactivateRecord: userId => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', userId, 'reactivateRecord', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getUserList: parameters => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', null, 'getUserList', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getInteractionListForMonthOfTeams: parameters => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', null, 'getInteractionListForMonthOfTeams', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getTopUserListInteractionsEffective: parameters => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', null, 'getTopUserListInteractionsEffective', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  getUserListEvents: parameters => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', null, 'getUserListEvents', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getInteractionTeams: parameters => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', null, 'getInteractionTeams', null), parameters)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  getInteracionsInTeam: parameters => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', parameters?.id, 'getInteracionsInTeam', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getInteracions: (idUser, parameters) => {
    return axios
      .post(MainServices.getApiBaseURL('admin', 'users', idUser, 'getInteractions', null), parameters)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getEventList: (idUser, parameters) => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', idUser, 'getEventList', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  getTaskList: (idUser, parameters) => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', idUser, 'getTaskList', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  getOpportunityList: (idUser, parameters) => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', idUser, 'getOpportunityList', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getInteractionTypes: (idUser, parameters) => {
    return axios
      .get(MainServices.getApiBaseURL('admin', 'users', idUser, 'getInteractionTypes', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  //#endregion
};

export default UserService;
