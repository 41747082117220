import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  buttonsActionRoot: {
    display: 'grid',
    justifyContent: 'center',
    '&> :not(:first-child)': {
      marginLeft: 0,
    },
  },
  contentRoot: {
    display: 'grid',
    justifyContent: 'center',
  },
  buttonActions: {
    marginTop: 8,
    marginBottom: 8,
  },
  boxInputCode: {
    display: 'grid',
    justifyContent: 'center',
  },
  textCodeRoot: {
    width: 320,
    margin: 8,
  },
  inputCodeRoot: {
    fontSize: '30px',
    fontWeight: '900',
    paddingLeft: '.5rem',
    letterSpacing: '2.5ch',
    background:
      'repeating-linear-gradient(90deg, dimgrey 0, dimgrey $char-w, transparent 0, transparent $char-w + $gap) 0 100%/ #{$in-w - $gap} 2px no-repeat',
    font: '5ch droid sans mono, consolas',
  },
  loading: {
    marginLeft: 8,
    color: theme.palette.common.white,
  },
}));

export default useStyles;
