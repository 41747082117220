import React, { useState } from 'react';
import { fade, IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import DynamicIconReact from '@aura/DynamicIconReact';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import SessionHelper from 'helpers/SessionHelper';
import Access from 'models/Generals/Access';
const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    color: fade(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
}));
const HeaderSupport = () => {
  const classes = useStyles();
  const history = useHistory();
  const [accessReferenceEvent] = useState('support.index');
  const onClickApp = () => {
    history.push('/app/support');
  };
  return (
    <div>
      {SessionHelper.ValidateAccess(Access.Set(accessReferenceEvent)) && (
        <Tooltip title="Soporte">
          <IconButton onClick={onClickApp} className={clsx(classes.iconRoot, 'Cmt-appIcon')}>
            <DynamicIconReact name="BiSupport" size={'sm'} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default HeaderSupport;
