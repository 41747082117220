import Alert from 'models/Generals/Alert';
import {
  SET_NOTIFICATIONS,
  ADD_NOTIFICATION,
  FETCH_START,
  FETCH_SUCCESS,
  UPDATE_NOTIFICATIONS,
} from '../../@jumbo/constants/ActionTypes';
import NotificationService from '../../services/Utilities/NotificationService';
import { fetchAlert } from './Common';

export const getNotifications = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
    NotificationService.getNotificationsUser().then(response => {
      dispatch({
        type: FETCH_SUCCESS,
      });
      if (response.satisfactory) {
        const notifications = response?.data?.data;
        dispatch({
          type: SET_NOTIFICATIONS,
          payload: notifications,
        });
        return;
      }
      dispatch(fetchAlert(Alert.Set(response.satisfactory, response.title, response.message)));
    });
  };
};

export const addNewNotification = newNotification => {
  return dispatch => {
    dispatch({
      type: ADD_NOTIFICATION,
      payload: newNotification,
    });
  };
};

export const readNotifications = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
    NotificationService.updateReadNotifications().then(response => {
      dispatch({
        type: FETCH_SUCCESS,
      });
      if (response.satisfactory) {
        const notifications = response?.data?.data;
        dispatch({
          type: SET_NOTIFICATIONS,
          payload: notifications,
        });
        return;
      }
      dispatch(fetchAlert(Alert.Set(response.satisfactory, response.title, response.message)));
    });
  };
};

export const setNotifications = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_NOTIFICATIONS,
      payload: user,
    });
  };
};
