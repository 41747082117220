import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import MainApp from './App';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Login from './Auth/Login';
import LoginSuperAdmin from './Auth/LoginSuperAdmin';
import Error401 from './Pages/401';
import Error404 from './Pages/404';
import TermsConditions from './Pages/TermsConditions';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (authUser) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + authUser.token;
  }

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/app'} />;
  } else if (authUser && (location.pathname === '/login' || location.pathname === '/login/super-admin')) {
    return <Redirect to={'/app'} />;
  }
  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/app" component={MainApp} />
        <Route exact path="/login/super-admin" component={LoginSuperAdmin} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/unauthorized" component={Error401} />
        <Route exact path="/terms-conditions" component={TermsConditions} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
