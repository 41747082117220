class Alert {
  static Set(satisfactory, title, message, duration, type) {
    return {
      satifactory: Boolean(satisfactory),
      title: title,
      message: message,
      duration: duration > 0 ? duration : 5000,
      type: !type ? (Boolean(satisfactory) ? 'success' : 'error') : type,
    };
  }

  static Reset() {
    return {
      satifactory: null,
      title: null,
      message: null,
      duration: null,
      type: null,
      typeNotification: null,
    };
  }
}

export default Alert;
