import MainServices from 'services/MainServices';
class SessionHelper {
  static ValidateAccess(authorization) {
    let hasAccess = false;
    const authorizations = MainServices.DecryptData(localStorage.getItem('authorizations'));
    if (authorizations && authorizations?.length > 0) {
      let authorizationFound = authorizations.filter(
        auth =>
          (authorization.access_reference && auth.access_reference === authorization.access_reference) ||
          (authorization.module_id && auth.module_id === authorization.module_id) ||
          (authorization.access_id && auth.access_id === authorization.access_id),
      );
      hasAccess = authorizationFound?.length > 0 ? true : false;
    }
    return hasAccess;
  }
}

export default SessionHelper;
