import Field from 'models/Generals/Field';
import moment from 'moment';

class GeneralsHelper {
  //#region WE RESIZE THE CANVAS DOWN WHEN SAVING ON RETINA DEVICES OTHERWISE THE IMAGE
  static getResizedCanvas(img, maxWidth, maxHeight, degrees) {
    let imgWidth = img.width;
    let imgHeight = img.height;

    let ratio = 1;
    let ratio1 = 1;
    let ratio2 = 1;
    ratio1 = maxWidth / imgWidth;
    ratio2 = maxHeight / imgHeight;

    // USE THE SMALLEST RATIO THAT THE IMAGE BEST FIT INTO THE MAXWIDTH X MAXHEIGHT BOX.
    if (ratio1 < ratio2) {
      ratio = ratio1;
    } else {
      ratio = ratio2;
    }
    let canvas = document.createElement('canvas');
    let canvasContext = canvas.getContext('2d');
    let canvasCopy = document.createElement('canvas');
    let copyContext = canvasCopy.getContext('2d');
    let canvasCopy2 = document.createElement('canvas');
    let copyContext2 = canvasCopy2.getContext('2d');
    canvasCopy.width = imgWidth;
    canvasCopy.height = imgHeight;
    copyContext.drawImage(img, 0, 0);

    // INIT
    canvasCopy2.width = imgWidth;
    canvasCopy2.height = imgHeight;
    copyContext2.drawImage(
      canvasCopy,
      0,
      0,
      canvasCopy.width,
      canvasCopy.height,
      0,
      0,
      canvasCopy2.width,
      canvasCopy2.height,
    );

    let rounds = 1;
    let roundRatio = ratio * rounds;
    for (let i = 1; i <= rounds; i++) {
      // TMP
      canvasCopy.width = (imgWidth * roundRatio) / i;
      canvasCopy.height = (imgHeight * roundRatio) / i;

      copyContext.drawImage(
        canvasCopy2,
        0,
        0,
        canvasCopy2.width,
        canvasCopy2.height,
        0,
        0,
        canvasCopy.width,
        canvasCopy.height,
      );

      // COPY BACK
      canvasCopy2.width = (imgWidth * roundRatio) / i;
      canvasCopy2.height = (imgHeight * roundRatio) / i;
      copyContext2.drawImage(
        canvasCopy,
        0,
        0,
        canvasCopy.width,
        canvasCopy.height,
        0,
        0,
        canvasCopy2.width,
        canvasCopy2.height,
      );
    } // END FOR

    canvas.width = (imgWidth * roundRatio) / rounds;
    canvas.height = (imgHeight * roundRatio) / rounds;
    canvasContext.drawImage(canvasCopy2, 0, 0, canvasCopy2.width, canvasCopy2.height, 0, 0, canvas.width, canvas.height);

    if (degrees === 90 || degrees === 270) {
      canvas.width = canvasCopy2.height;
      canvas.height = canvasCopy2.width;
    } else {
      canvas.width = canvasCopy2.width;
      canvas.height = canvasCopy2.height;
    }

    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    if (degrees === 90 || degrees === 270) {
      canvasContext.translate(canvasCopy2.height / 2, canvasCopy2.width / 2);
    } else {
      canvasContext.translate(canvasCopy2.width / 2, canvasCopy2.height / 2);
    }
    canvasContext.rotate((degrees * Math.PI) / 180);
    canvasContext.drawImage(canvasCopy2, -canvasCopy2.width / 2, -canvasCopy2.height / 2);
    return canvas;
  }
  //#endregion

  static getMeasurementType(type) {
    if (!type || type === '') {
      return '';
    }
    const measurementType = {
      STATUS: 'ESTADOS',
      ACHIEVED_VALUE: 'VALOR ALCANZADO',
    };
    return measurementType[type];
  }

  static getCreationBy(creation) {
    if (!creation || creation === '') {
      return '';
    }
    const creationBy = {
      ALL_USERS: 'TODOS LOS USUARIOS',
      TEAMS: 'EQUIPOS',
      ASSIGNED_BASE: 'BASE ASIGNADA',
      PORTFOLIO: 'PORTAFOLIO',
      DELETED: '#FF2A00',
      EXECUTED: '#21823E',
      DISCARD: '#FF2A00',
    };
    return creationBy[creation];
  }

  static getBgColorStatus(status) {
    const color = {
      ACTIVE: '#21823E',
      INACTIVE: '#FF8C00',
      CONFIRMATED: '#21823E',
      RESOLVED: '#21823E',
      PENDING: '#ff8c00cf',
      DELETED: '#FF2A00',
      EXECUTED: '#21823E',
      DISCARD: '#FF2A00',
      CLOSE: '#f50057',
      REVISION: '#3f51b5ad',
      DISCARDED: '#FF2A00',
      ENTRY: '#3399FF',
    };
    return color[status];
  }
  static getSpanishStatus(status) {
    const spanish = {
      ACTIVE: 'ACTIVO',
      INACTIVE: 'INACTIVO',
      CONFIRMATED: 'CONFIRMADO',
      RESOLVED: 'RESUELTO',
      PENDING: 'PENDIENTE',
      DELETED: 'ELIMINADO',
      EXECUTED: 'EJECUTADO',
      DISCARD: 'DESCARTAR',
      CLOSE: 'CERRAR',
      REVISION: 'REVISIÓN',
      DISCARDED: 'DESCARTADO',
      ENTRY: 'ENTRADA',
    };
    return spanish[status];
  }

  static getCharacterData(character) {
    const arrayCharacters = {
      ALL: 'TODOS',
      INTEGERS: 'ENTEROS',
      DECIMALS: 'DECIMALES',
      ONLY_LETTERS: 'SOLO LETRAS',
      LETTER_AND_NUMBERS: 'LETRAS Y NÚMEROS',
      PERCENTAGE: 'PORCENTAJE',
    };
    return arrayCharacters[character];
  }

  static getTypeLocationPoint(status) {
    const color = {
      LAST_LOCATION: 'ÚLTIMA LOCALIZACIÓN',
      LOCATION: 'LOCALIZACIÓN',
      INTERACTION: 'INTERACCIÓN',
      CHANGE_STATUS_WORKING: 'CAMBIO ESTADO LABORAL',
    };
    return color[status];
  }

  static getAssignmentType(status) {
    const color = {
      OWN: 'PROPIO',
      ASSIGNED_BASE: 'BASE ASIGNADA',
      RE_ASSIGNED: 'RE ASIGNADO',
    };
    return color[status];
  }
  static getMeterType(status) {
    const color = {
      OPPORTUNITIES_COUNT: 'CONTADOR DE OPORTUNIDADES POR ESTADO',
      OPPORTUNITIES_SUM_ACHIEVED_VALUE: 'SUMA DE VALORES ALCANZADOS POR ESTADO',
      INTERACTIONS_COUNT_USER: 'CONTADOR DE INTERACCIONES',
      INTERACTIONS_COUNT_USER_ACCOMPANIMENT: 'CONTADOR DE INTERACCIONES DE ACOMPAÑAMIENTO ',
      SALES_COUNT: 'CONTADOR DE VENTAS',
      SALES_SUM_TOTAL_VALUES: 'SUMA DE VALORES TOTALES EN VENTAS',
      SALES_ITEMS_COUNT: 'CONTADOR DE ITEMS EN VENTAS',
      SALES_ITEMS_TOTAL_VALUES: 'SUMA DE VALORES TOTALES EN VENTAS POR ITEMS',
    };
    return color[status];
  }
  static nameTypeQuestion = type => {
    const typeIconObject = {
      INPUT_TEXT: 'TEXTO CORTO',
      INPUT_TEXTAREA: 'TESTO LARGO',
      LIST_OPTION: 'LISTA DE OPCIONES',
      LIST_MULTI_OPTIONS: 'MULTI LISTA DE OPCIONES',
      DATE: 'FECHA',
      PHOTOGRAPH: 'IMAGEN',
    };
    return typeIconObject[type] ?? '';
  };

  static isset(consulta) {
    //LLAMAR: GeneralsHelper.isset(() => objeto) OR: GeneralsHelper.isset(() => objeto.campo)
    try {
      return typeof consulta() !== 'undefined';
    } catch (e) {
      return false;
    }
  }

  static completeNameReduced(user, initCapitalLetters, inverted = false) {
    var completeName = '';

    if (user != null) {
      var nameSeparated = user.name != null ? user.name.split(' ')[0] : '';
      var apellidosSeparados = user.lastname != null ? user.lastname : '';

      completeName = apellidosSeparados + ' ' + nameSeparated;
      if (inverted) {
        completeName = nameSeparated + ' ' + apellidosSeparados;
      }

      if (initCapitalLetters) {
        completeName = GeneralsHelper.UppercaseWord(completeName);
      }
    }

    return completeName;
  }

  static UppercaseWord(str) {
    var pieces = str.toLowerCase().split(' ');
    for (var i = 0; i < pieces.length; i++) {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(' ');
  }

  static getContrastTextByBackgroundColor(hexadecimal) {
    if (hexadecimal === '' || !hexadecimal) {
      hexadecimal = '#ffffff';
    }
    const arrayColorRgb = this.getRGBFromHexadecimal(hexadecimal.replace('#', ''));

    let o = Math.round(
      (parseInt(arrayColorRgb[0]) * 299 + parseInt(arrayColorRgb[1]) * 587 + parseInt(arrayColorRgb[2]) * 114) / 1000,
    );
    let fore = o > 125 ? '#000' : '#fff';
    return fore;
  }

  static getDifferenceDate(currentDate, endDateConnection, type) {
    const differenceDate = moment.duration(currentDate.diff(endDateConnection));
    switch (type) {
      case 'days':
        return parseInt(differenceDate.asDays());
      case 'hours':
        return parseInt(differenceDate.asHours());
      case 'seconds':
        return parseInt(differenceDate.asSeconds());
      default:
        return parseInt(differenceDate.asMinutes());
    }
  }

  static getLastConnection(currentDate, endDateConnection) {
    const differenceDate = moment.duration(currentDate.diff(endDateConnection));
    const days = differenceDate.get('days');
    const hours = differenceDate.get('hours');
    const minutes = differenceDate.get('minutes');
    const seconds = differenceDate.get('seconds');
    if (+days > 0) {
      return `${days} día${days > 1 ? 's' : ''}`;
    }
    if (+hours > 0) {
      return `${hours} hora${hours > 1 ? 's' : ''}`;
    }
    if (+minutes > 0) {
      return `${minutes} minuto${minutes > 1 ? 's' : ''}`;
    }
    if (+seconds > 0) {
      return `${seconds} segundo${seconds > 1 ? 's' : ''}`;
    }
    return '';
  }

  static convertFormat12Hours(time) {
    const arrayTime = time.split(':');
    let ampm = 'AM';
    if (arrayTime[0] >= 12) {
      ampm = 'PM';
    }
    if (arrayTime[0] > 12) {
      arrayTime[0] = arrayTime[0] - 12;
    }
    time = arrayTime[0] + ':' + arrayTime[1] + ':' + arrayTime[2] + ' ' + ampm;
    return time;
  }

  static getRGBFromHexadecimal(hexadecimal) {
    if (hexadecimal.indexOf('#') > -1) {
      hexadecimal = hexadecimal.replace('#', '');
    }
    var bigint = parseInt(hexadecimal, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return [r, g, b];
  }

  static getRGBFromHexadecimalOpacity(hexadecimal, opacity = 0.9) {
    if (hexadecimal.indexOf('#') > -1) {
      hexadecimal = hexadecimal.replace('#', '');
    }
    var bigint = parseInt(hexadecimal, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return `rgba(${r},${g},${b},${opacity})`;
  }

  static isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  static isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  static getValue(objeto, arrayPalabras) {
    //Función recursiva
    let primeraPalabra = arrayPalabras[0]; //Obtener la primera palabra del arrayPalabras
    arrayPalabras.splice(0, 1); //Eliminación de la primera palabra del array
    if (GeneralsHelper.isset(() => objeto[primeraPalabra])) {
      if (arrayPalabras.length === 0) {
        //Si solo existia una palabra, return el valor del objeto[primeraPalabra]
        return objeto[primeraPalabra];
      } else {
        //Sino invocar a la misma función
        return this.getValue(objeto[primeraPalabra], arrayPalabras);
      }
    } else {
      return null;
    }
  }

  static getCenterOfCoordinateList(coordinates) {
    if (coordinates?.length === 1) {
      return coordinates[0];
    }

    let x = 0;
    let y = 0;
    let z = 0;

    coordinates.forEach(coordinate => {
      let latitude = (coordinate?.lat * Math.PI) / 180;
      let longitude = (coordinate?.lng * Math.PI) / 180;

      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    });

    let total = coordinates?.length;

    x = x / total;
    y = y / total;
    z = z / total;

    let centerLongitude = Math.atan2(y, x);
    let centerSquareRoot = Math.sqrt(x * x + y * y);
    let centerLatitude = Math.atan2(z, centerSquareRoot);

    return { latitude: (centerLatitude * 180) / Math.PI, longitude: (centerLongitude * 180) / Math.PI };
  }

  static getRandomColor() {
    // var letters = '0123456789ABCDEF';
    // var color = '#';
    // for (var i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;
    let color = 'hsl(' + Math.random() * 360 + ', 100%, 75%)';
    return color;
  }

  static fieldsProcess(fiels) {
    let fields = [];
    if (fiels?.TableColumns?.length > 0) {
      fiels.TableColumns.forEach(fiel => {
        fields.push(new Field(fiel?.order, fiel?.title, fiel?.field_name, true, fiel.aling, 'tableCellSecond'));
      });
    }
    return fields;
  }
  static IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static onlyUniqueArray(value, index, self) {
    return self.indexOf(value) === index;
  }
}

export default GeneralsHelper;
