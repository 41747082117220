import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import IntlMessages from '../../../../utils/IntlMessages';
import Icon from '@material-ui/core/Icon';
import { useSelector } from 'react-redux';
import DynamicIconReact from '@aura/DynamicIconReact';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

function getSections(section) {
  if (section.sections.length === 0) {
    return {
      name: section.name,
      type: 'item',
      icon: <DynamicIconReact name={section.icon.class} size={'xs'}/>,
      link: '/app/' + section.url_reference,
    };
  } else {
    return {
      name: section.name,
      type: 'collapse',
      icon: <DynamicIconReact name={section.icon.class} size={'xs'}/>,
      children: section.sections.map(s => getSections(s)),
    };
  }
}
 

function ProcesarMenus(navigationMenu) {
  const NAV = [];

  if (navigationMenu != null) {
    navigationMenu.map(module => {
      let showMenu=module.sections.filter(s =>s.show_menu==="YES");
      NAV.push({
        name: module.name,
        type: 'section',
        icon: <DynamicIconReact name={module.icon?.class}/>,
        children: showMenu.map(s => getSections(s)),
      });
      return true;
    });
  }

  return NAV;
}
 
const SideBar = () => {
  const classes = useStyles();

  const { navigationMenu } = useSelector(({ auth }) => auth);

  const navigationMenus = ProcesarMenus(navigationMenu);

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />{' '}
    </PerfectScrollbar>
  );
};

export default SideBar;
