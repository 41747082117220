class Field {
  constructor(
    id,
    title,
    fieldName,
    visible,
    align = 'left',
    width = '',
    printingType = 'text',
    additionalValue = 'name',
    additionalValueRequired,
  ) {
    this.id = id;
    this.title = title;
    this.fieldName = fieldName;
    this.visible = visible; //TRUE O FALSE
    this.align = align;
    this.width = width; // Ancho de la columna
    this.printingType = printingType; //Valor para imprimir un texto u otro elemento como iconos o colores
    this.additionalValue = additionalValue; //Valor adicional para mostrar en tabla
    this.additionalValueRequired = additionalValueRequired; //Valor adicional para pintar la tabla
  }
}
export default Field;
