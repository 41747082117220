/* eslint-disable react-hooks/exhaustive-deps */
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtList from '@coremat/CmtList';
import { Box, fade, IconButton, makeStyles, Popover, Tooltip, useTheme } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from 'clsx';
import Echo from 'laravel-echo';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { addNewNotification, getNotifications, readNotifications } from 'redux/actions/Notification';
import MainServices from 'services/MainServices';
import UIfx from 'uifx';
import NotificationClip from '../../../../../../assets/sounds/NotificationClip.mp3';
import NotificationItem from './NotificationItem';

const notificationSound = new UIfx(NotificationClip, {
  volume: 0.5, // value must be between 0.0 ⇔ 1.0
  throttleMs: 50,
});

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .Cmt-card-content': {
      padding: '0 0 16px !important',
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    position: 'relative',
    color: fade(theme.palette.common.white, 0.38),
    '&:hover, &.active': {
      color: theme.palette.common.white,
    },
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20,
  },
  scrollbarRoot: {
    height: 300,
    padding: 16,
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 375,
    },
  },
}));

const HeaderNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { notificationList, pendingNotifications } = useSelector(({ notification }) => notification);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  function handleNotificationsRead() {
    dispatch(readNotifications());
  }

  function runNotificationSocket() {
    if (authUser) {
      let echoConfiguration = MainServices.echoConfiguration;
      echoConfiguration.auth = {
        headers: {
          //#region Optional parameters for Headers
          // Accept: 'application/json',
          // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
          Authorization: `Bearer ${authUser.token}`,
        },
      };
      let echoClient = new Echo(echoConfiguration);
      echoClient.private('notification-aura-crm-channel.' + authUser?.id).listen('.MessageAuraCrmEvent', event => {
        if (event?.notification) {
          notificationSound.play();
          dispatch(addNewNotification(event?.notification));
        }
      });
    }
  }

  const onOpenPopOver = event => {
    if (notificationList?.length > 0) {
      handleNotificationsRead();
    }
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const handleNotificationRefresh = () => {
    dispatch(getNotifications());
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (!notificationList && authUser) {
      dispatch(getNotifications());
    }
  }, []);

  useEffect(() => {
    runNotificationSocket();
    return () => {
      runNotificationSocket();
    };
  }, []);

  return (
    <Box pr={2}>
      <Tooltip title="Notificaciones">
        <IconButton
          onClick={onOpenPopOver.bind(this)}
          className={clsx(classes.iconRoot, 'Cmt-appIcon', {
            active: pendingNotifications > 0,
          })}>
          <Badge badgeContent={pendingNotifications} classes={{ badge: classes.counterRoot }}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Notificaciones"
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}>
            <IconButton size="small">
              <RefreshIcon onClick={handleNotificationRefresh.bind(this)} />
            </IconButton>
          </CmtCardHeader>
          <CmtCardContent>
            {Array.isArray(notificationList) && notificationList?.length > 0 ? (
              <PerfectScrollbar className={classes.scrollbarRoot}>
                <CmtList data={notificationList} renderRow={(item, index) => <NotificationItem key={index} item={item} />} />
              </PerfectScrollbar>
            ) : (
              <Box p={6}>
                <Typography variant="body2">No tiene notificaciones</Typography>
              </Box>
            )}
          </CmtCardContent>
        </CmtCard>
      </Popover>
    </Box>
  );
};

export default HeaderNotifications;
