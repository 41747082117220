import { IconButton, Fade } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { AlertTitle } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, resetAlert } from '../../../redux/actions';
import PageLoader from '../PageComponents/PageLoader';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

const ContentLoader = () => {
  const { error, loading, alert, message } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchError(''));
    }, 3000);
  }, [dispatch, error, message, alert]);

  const classes = useStyles();

  return (
    <React.Fragment>
      {loading && <PageLoader />}
      {error && <Snackbar open={Boolean(error)} message={error} />}
      {message && <Snackbar open={Boolean(message)} message={message} />}

      {alert.title && (
        <Snackbar
          open={alert.title}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={alert.duration}
          TransitionComponent={Fade}
          onClose={() => {
            dispatch(resetAlert());
          }}>
          <Alert
            severity={alert.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={() => {
                  dispatch(resetAlert());
                }}>
                <CloseIcon />
              </IconButton>
            }>
            <AlertTitle>{alert.title}</AlertTitle>
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default ContentLoader;
