import CmtImage from '@coremat/CmtImage';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  errorNumber: {
    color: theme.palette.text.primary,
    fontWeight: 800,
    lineHeight: 1,
    marginBottom: 30,
    textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
  },
  searchRoot: {
    position: 'relative',
    width: 260,
    textAlign: 'center',
  },
}));

const Unauthorized401 = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box fontSize={{ xs: 100, sm: 160 }} className={classes.errorNumber}>
        401
      </Box>
      <Box className={classes.searchRoot}>
        <CmtImage src={'/images/unauthorized.png'} alt="unauthorized" width={200} height={200} />
      </Box>
      <Box fontSize={{ xs: 16, sm: 24 }} mb={8} color="grey.500">
        <IntlMessages id="extraPages.401Msg" />
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/');
          }}>
          <IntlMessages id="extraPages.goHome" />
        </Button>
      </Box>
    </Box>
  );
};

export default Unauthorized401;
