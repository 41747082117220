import axios from 'axios';
import MainServices from '../MainServices';

let TeamService = {
  //#region SERVICES ADMINISTRATION
  getIndex: parameters => {
    return axios
      .get(MainServices.getApiBaseURL('core', 'teams', null, null, parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  postStore: formData => {
    return axios
      .post(MainServices.getApiBaseURL('core', 'teams', null, null, null), formData)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  putUpdate: formData => {
    return axios
      .put(MainServices.getApiBaseURL('core', 'teams', formData?.id, null, null), formData)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  deleteDestroy: teamId => {
    return axios
      .delete(MainServices.getApiBaseURL('core', 'teams', teamId, null, null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  //#endregion

  //#region Methods
  getTeamHasManagementUser: () => {
    return axios
      .get(MainServices.getModuloURLAdminwithFunction('core', 'teams', null, 'getTeamHasManagementUser', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getUsersManagementByTeam: teamId => {
    return axios
      .get(MainServices.getModuloURLAdminwithFunction('core', 'teams', teamId, 'getUsersManagementByTeam', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getUsersManagementRoutesByTeam: (teamId, parameters) => {
    return axios
      .get(MainServices.getModuloURLAdminwithFunction('core', 'teams', teamId, 'getUsersManagementRoutesByTeam', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getUsersAccompanimentByTeam: (teamId, parameters) => {
    return axios
      .get(MainServices.getModuloURLAdminwithFunction('core', 'teams', teamId, 'getUsersAccompanimentByTeam', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getTeamsUserList: teamId => {
    return axios
      .get(MainServices.getModuloURLAdminwithFunction('core', 'teams', teamId, 'getTeamsUserList', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getUserListLocationPoints: teamId => {
    return axios
      .get(MainServices.getModuloURLAdminwithFunction('core', 'teams', teamId, 'getUserListLocationPoints', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getUserListRoute: (teamId, parameters) => {
    return axios
      .get(MainServices.getModuloURLAdminwithFunction('core', 'teams', teamId, 'getUserListRoute', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getItineraryList: (teamId, parameters) => {
    return axios
      .get(MainServices.getApiBaseURL('core', 'teams', teamId, 'getItineraryList', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getItineraryListAssignment: (teamId, parameters) => {
    return axios
      .get(MainServices.getApiBaseURL('core', 'teams', teamId, 'getItineraryListAssignment', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getDaysInteractionInformation: (teamId, parameters) => {
    return axios
      .post(
        MainServices.getModuloURLAdminwithFunction('core', 'teams', teamId, 'getDaysInteractionInformation', null),
        parameters,
      )
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getInteractionsStatisticsByStatus: (teamId, parameters) => {
    return axios
      .post(
        MainServices.getModuloURLAdminwithFunction('core', 'teams', teamId, 'getInteractionsStatisticsByStatus', null),
        parameters,
      )
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getInteractionsVsEffectiveness: (teamId, parameters) => {
    return axios
      .post(
        MainServices.getModuloURLAdminwithFunction('core', 'teams', teamId, 'getInteractionsVsEffectiveness', null),
        parameters,
      )
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getTeamListUsingManagementUser: () => {
    return axios
      .get(MainServices.getApiBaseURL('core', 'teams', null, 'getTeamListUsingManagementUser', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  reactivateRecord: teamsId => {
    return axios
      .post(MainServices.getApiBaseURL('core', 'teams', teamsId, 'reactivateRecord', null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  getInteractionListForMonth: parameters => {
    return axios
      .get(MainServices.getModuloURLAdminwithFunction('core', 'teams', null, 'getInteractionListForMonth', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },

  //#endregion
};

export default TeamService;
