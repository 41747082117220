import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import 'react-day-picker/lib/style.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

const UnsecuredPageWarning = withStyles(theme => ({
  color: 'red',
}))(Typography);

const Link = withStyles(theme => ({
  textDecoration: 'none',
  color: 'red',
}))(Typography);

const UnsecuredPage = () => (
  <div>
    <UnsecuredPageWarning>
      If you see this page, Webb App link you have clicked on is under Clickjacking security attack.
    </UnsecuredPageWarning>
    <h2>Please inform team with the reference of the application from where you clicked this link.</h2>
    <h2>
      Click{' '}
      <Link href={window.self.location.href} title="Web Application" target="blank">
        here
      </Link>{' '}
      to access WebApp safely.
    </h2>
  </div>
);

export default UnsecuredPage;
