import axios from 'axios';
import MainServices from '../MainServices';

let NotificationService = {
  //#region SERVICES API RESOURCES
  getIndex: parameters => {
    return axios
      .get(MainServices.getApiBaseURL('utilities', 'notifications', null, null, parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  postStore: formData => {
    return axios
      .post(MainServices.getApiBaseURL('utilities', 'notifications', null, null, null), formData)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  putUpdate: formData => {
    return axios
      .put(MainServices.getApiBaseURL('utilities', 'notifications', formData?.id, null, null), formData)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  deleteDestroy: notificationId => {
    return axios
      .delete(MainServices.getApiBaseURL('utilities', 'notifications', notificationId, null, null))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  //#endregion

  getNotificationsUser: parameters => {
    return axios
      .get(MainServices.getApiBaseURL('utilities', 'notifications', null, 'getNotificationsUser', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  updateReadNotifications: parameters => {
    return axios
      .get(MainServices.getApiBaseURL('utilities', 'notifications', null, 'updateReadNotifications', parameters))
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
  sendNotifications: formData => {
    return axios
      .post(MainServices.getApiBaseURL('utilities', 'notifications', null, 'sendNotifications', null), formData)
      .then(res => {
        return MainServices.processResponse(true, res.data);
      })
      .catch(error => {
        return MainServices.processResponse(false, null, error);
      });
  },
};

export default NotificationService;
