import Alert from 'models/Generals/Alert';
import UserService from 'services/Admin/UserService';
import {
  FETCH_START,
  FETCH_SUCCESS,
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTHORIZATIONS,
  UPDATE_AUTH_LOGOUT,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  UPDATE_NAVIGATIONMENU,
  UPDATE_WIDGETS,
  UPDATE_TEAMSTRUCTURE,
  UPDATE_PROFILE_WIDGETS,
} from '@jumbo/constants/ActionTypes';
import { fetchAlert } from './Common';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const setLogout = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_LOGOUT,
      payload: user,
    });
  };
};

export const setAuthorizations = authorizations => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTHORIZATIONS,
      payload: authorizations,
    });
  };
};

export const setNavigationMenu = navigationMenu => {
  return dispatch => {
    dispatch({
      type: UPDATE_NAVIGATIONMENU,
      payload: navigationMenu,
    });
  };
};

export const setWidgets = widgets => {
  return dispatch => {
    dispatch({
      type: UPDATE_WIDGETS,
      payload: widgets,
    });
  };
};

export const setProfileWidgets = widgets => {
  return dispatch => {
    dispatch({
      type: UPDATE_PROFILE_WIDGETS,
      payload: widgets,
    });
  };
};

export const setTeamStructure = teamStructure => {
  return dispatch => {
    dispatch({
      type: UPDATE_TEAMSTRUCTURE,
      payload: teamStructure,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};

export const authLogin = (username, password, username_admin) => {
  return dispatch => {
    const parameters = {
      username: username,
      password: password,
      username_admin: username_admin,
    };
    dispatch({
      type: FETCH_START,
    });
    UserService.postUserLogin(parameters).then(response => {
      dispatch(fetchAlert(Alert.Set(response.satisfactory, response.title, response.message)));
      dispatch({
        type: FETCH_SUCCESS,
      });
      if (response.satisfactory) {
        dispatch(setAuthUser(response.data.user));
        dispatch(setAuthorizations(response.data.authorizations));
        dispatch(setNavigationMenu(response.data.navigationMenu));
      }
    });
  };
};

export const updateAuthorizations = userId => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
    UserService.updateAuthorizations(userId).then(response => {
      dispatch({
        type: FETCH_SUCCESS,
      });
      if (response.satisfactory) {
        dispatch(setAuthUser(response.data.user));
        dispatch(setAuthorizations(response.data.authorizations));
        dispatch(setNavigationMenu(response.data.navigationMenu));
        dispatch(setWidgets(response.data.widgets));
        dispatch(setProfileWidgets(response.data.profileWidgets));
        dispatch(setTeamStructure(response.data.teamStructure));
        return;
      }
      dispatch(fetchAlert(Alert.Set(response.satisfactory, response.title, response.message)));
    });
  };
};
