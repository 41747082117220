import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fade, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';
import CmtAvatar from '@coremat/CmtAvatar';
import CmtMediaObject from '@coremat/CmtMediaObject';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import GetAppIcon from '@material-ui/icons/GetApp';
import DynamicIconReact from '@aura/DynamicIconReact';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  feedItemRoot: {
    padding: '10px 0',
    position: 'relative',
    cursor: 'pointer',
    borderBottom: `1px solid ${fade(theme.palette.common.dark, 0.035)}`,
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
    '& .Cmt-media-image': {
      alignSelf: 'flex-start',
      width: 56,
    },
    '& .Cmt-media-body': {
      width: 'calc(100% - 56px)',
      flex: 'inherit',
    },
  },
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: 'pointer',
  },
  iconDownload: {
    position: 'absolute',
    right: 8,
    bottom: 0,
  },
  avatarImgUser: {
    padding: 8,
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

const getDownloadContent = (item, classes) => {
  let nameReport = '';
  const posIn = item?.contents.indexOf('[');
  const posOut = item?.contents.indexOf(']');
  if (posIn > -1 && posOut > -1) {
    nameReport = item?.contents.substring(posIn, posOut + 1);
  }
  const description = item?.contents.replace(nameReport, '<ReportName>').split('<ReportName>');

  return (
    <Typography component="div" variant="h5" className={classes.titleRoot}>
      <Box component="span" ml={1}>
        {description[0]}{' '}
        <Box component="span" color="primary.main">
          {nameReport}
        </Box>{' '}
        {description[1]}
      </Box>
    </Typography>
  );
};

const NotificationItem = ({ item }) => {
  const history = useHistory();
  const classes = useStyles();
  const icons = {
    DOWNLOAD: <InsertDriveFileIcon />,
    MESSAGE: <DynamicIconReact name={'FaBell'} />,
  };

  const getTitle = (item, classes) => {
    switch (item.type) {
      case 'DOWNLOAD':
        return getDownloadContent(item, classes);
      case 'MESSAGE':
        return getDownloadContent(item, classes);
      default:
        return '';
    }
  };

  const handleFileDownload = () => {
    const link = document.createElement('a');
    link.href = item?.link_url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const onClickOpenLinkApp = () => {
    history.push('/app/' + item?.link_url);
  };
  const OpenLink = () => {
    if (item.type === 'DOWNLOAD') {
      handleFileDownload();
    }
    if (item.type === 'MESSAGE' && item.link_url !== null) {
      onClickOpenLinkApp();
    }
  };
  const getSubTitle = () => (
    <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
      <Box fontSize={16} clone>
        {icons[item.type]}
      </Box>
      <Box ml={2}>{moment(item.created_at).format('yyyy-MM-DD HH:mm:ss')}</Box>
    </Box>
  );

  return (
    <Box className={classes.feedItemRoot} onClick={OpenLink.bind(this)}>
      <CmtMediaObject
        avatarPos="center"
        avatar={
          <CmtAvatar
            className={classes.avatarImgUser}
            size={40}
            src={'/images/logo_sm.png'}
            alt={item?.link_name ?? 'ALT'}
          />
        }
        title={getTitle(item, classes)}
        subTitle={getSubTitle()}
      />
    </Box>
  );
};

export default NotificationItem;
